<template>
  <div class="customerRechargeReview">
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <el-form-item label="状态" class="col-auto">
          <el-select style="width: 110px" v-model="params.apply_status" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="提交时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="提交人名称" value="open_card_personnel_name"></el-option>
              <el-option label="提交人手机号" value="open_card_personnel_mobile"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
      </template>
      <template slot="table-columns">
        <el-table-column prop="customer_name" show-overflow-tooltip label="客户名称" fixed="left" />
        <el-table-column label="充值金额" width="100" prop="recharge_amount" fixed="left">
          <template slot-scope="scope">
            {{ scope.row.recharge_amount | unitPrice('￥') }}
          </template>
        </el-table-column>
        <el-table-column prop="recharge_type" label="充值方式" width="100" fixed="left">
          <template slot-scope="{row}">{{ row.recharge_type == 1 ? '线下汇款' : '' }}</template>
        </el-table-column>
        <el-table-column prop="recharge_time" label="提交时间" width="160">
          <template slot-scope="scope">{{ scope.row.recharge_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="open_card_personnel_name" label="提交人名称" width="200" />
        <el-table-column prop="open_card_personnel_mobile" label="提交人手机号" width="130" />
        <el-table-column prop="account_period" label="账期类型" width="100">
          <template slot-scope="scope">
            {{ scope.row.account_period ? '有账期' : '无账期' }}
          </template>
        </el-table-column>
        <el-table-column prop="apply_status" label="状态" width="80">
          <template slot-scope="scope">
            {{ scope.row.apply_status | statusFilter }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="scope.row.apply_status === 0" size="mini" type="primary"
              @click="handleGoodsAudit(scope.row)">
              审核
            </el-button>
            <el-button size="mini" type="primary" @click="openDetail(scope.row)">查看
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!--审核 dialog-->
    <x-dialog :proxy="examinationDialog">
      <el-form class="orderDetail" style="padding:20px" :model="goodsAuditForm" ref="goodsAuditForm"
        label-width="100px">
        <el-form-item label="客户名称">{{ goodsAuditForm.customer_name }}</el-form-item>
        <el-form-item label="账期类型"> {{ goodsAuditForm.account_period ? '有账期' : '无账期' }}</el-form-item>
        <el-form-item label="提交时间">{{ goodsAuditForm.recharge_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</el-form-item>
        <el-form-item label="状态">{{ goodsAuditForm.apply_status | statusFilter }}</el-form-item>
        <el-form-item label="提交人名称">{{ goodsAuditForm.open_card_personnel_name }}</el-form-item>
        <el-form-item label="提交人手机号">{{ goodsAuditForm.open_card_personnel_mobile }}</el-form-item>
        <el-form-item label="充值金额">
          {{ goodsAuditForm.recharge_amount | unitPrice("￥") }}
        </el-form-item>
        <el-form-item label="充值方式">
          {{ goodsAuditForm.recharge_type == 1 ? '线下汇款' : '' }}
        </el-form-item>
        <el-form-item label="图片凭证">
          <div style="display: flex;" v-if="goodsAuditForm.image_voucher">
            <img style="width:100px;height:100px;margin: 0 5px 20px;"
              v-for="(item, index) in JSON.parse(goodsAuditForm.image_voucher)" @click="handleImgLook(item)"
              :key="index" :src="item.url" alt class="pass_img" />
          </div>
        </el-form-item>
        <el-form-item label="备注信息">{{ goodsAuditForm.remark }}</el-form-item>
        <el-form-item label="审核" prop="status">
          <el-radio-group v-model="Auditing.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拒绝原因" label-width="120px" v-if="Auditing.status === 2">
          <el-input type="textarea" v-model.trim="Auditing.reason" maxlength="100" placeholder="拒绝原因最多不超100字。"
            :rows="5"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!-- 查看 -->
    <x-dialog :proxy="showWithDrawalInfoProxy">
      <el-form class="orderDetail" :model="goodsAuditForm" label-width="120px">
        <el-form-item label="客户名称">{{ goodsAuditForm.customer_name }}</el-form-item>
        <el-form-item label="账期类型"> {{ goodsAuditForm.account_period ? '有账期' : '无账期' }}</el-form-item>
        <el-form-item label="提交时间">{{ goodsAuditForm.recharge_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</el-form-item>
        <el-form-item label="状态">{{ goodsAuditForm.apply_status | statusFilter }}</el-form-item>
        <el-form-item label="提交人名称">{{ goodsAuditForm.open_card_personnel_name }}</el-form-item>
        <el-form-item label="提交人手机号">{{ goodsAuditForm.open_card_personnel_mobile }}</el-form-item>
        <el-form-item label="充值金额">
          {{ goodsAuditForm.recharge_amount | unitPrice("￥") }}
        </el-form-item>
        <el-form-item label="充值方式">
          {{ goodsAuditForm.recharge_type == 1 ? '线下汇款' : '' }}
        </el-form-item>
        <el-form-item label="图片凭证">
          <div style="display: flex;" v-if="goodsAuditForm.image_voucher">
            <img style="width:100px;height:100px;margin: 0 5px 20px;"
              v-for="(item, index) in JSON.parse(goodsAuditForm.image_voucher)" @click="handleImgLook(item)"
              :key="index" :src="item.url" alt class="pass_img" />
          </div>
        </el-form-item>
        <el-form-item label="备注信息">{{ goodsAuditForm.remark }}</el-form-item>
        <el-form-item label="拒绝原因" v-if="goodsAuditForm.apply_status === 2">{{ goodsAuditForm.apply_audit_reject_remark
          }}
        </el-form-item>
      </el-form>
    </x-dialog>

    <!-- 查看大图 -->
    <el-dialog :visible.sync="centerDialogVisible" modal close-on-click-modal custom-class="dialog">
      <el-carousel :autoplay="false" arrow="never">
        <el-carousel-item v-for="(item, index) in centerDialogList" :key="index">
          <img :src="item.url" style="width: 100%; height: 100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { getCustomerRechargeAuditList, customerRechargeAuditAuditing, customerRechargeAuditDetail } from '@/api/settingCustomer';
export default {
  name: 'customerRechargeReview',
  components: { EnTableLayout, XDialog, },
  filters: {
    statusFilter (status) {
      const statusMap = {
        0: '待审核',
        1: '已通过',
        2: '已拒绝'
      }
      return statusMap[status]
    }
  },
  data () {
    return {
      Auditing: {//审核
        status: 1,
        reason: ''
      },
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '已通过'
        },
        {
          value: 2,
          label: '已拒绝'
        },
      ],
      key_word: 'customer_name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        recharge_source: 2, //充值来源 1：企业后台 2：移动端前台
        apply_status: '',
        keyWord: "",
      },
      // 列表数据
      tableData: {
        data: []
      },
      order_time_range: [],
      examinationDialog: $xDialog.create({
        title: "审核操作",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm
      }),
      showWithDrawalInfoProxy: $xDialog.create({
        title: '查看',
        wrapperClass: 'show-withdrawal-info',
        width: '50vw',
        disableCancel: true,
        disableConfirm: true
      }),
      goodsAuditForm: {},
      centerDialogVisible: false, // 显示查看器
      centerDialogList: [],
    }
  },
  mounted () {
    this.GET_GoodsAuditList();
  },
  methods: {
    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.recharge_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.recharge_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.recharge_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.recharge_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.recharge_time_start = "";
        this.params.recharge_time_end = "";
      }
    },
    //图片预览
    handleImgLook (url) {
      this.centerDialogList = [url];
      this.centerDialogVisible = true;
    },
    /**打卡详情弹窗 */
    openDetail (row) {
      this.showWithDrawalInfoProxy.display();
      customerRechargeAuditDetail(row.id).then(res => {
        this.goodsAuditForm = res;
      })
    },
    /** 打开审核商品 */
    handleGoodsAudit (row) {
      this.Auditing = {//审核
        status: 1,
        reason: ''
      }
      this.examinationDialog.display();
      customerRechargeAuditDetail(row.id).then(res => {
        this.goodsAuditForm = res;
      })
    },
    /**提交审核 */
    submitGoodsAuditForm () {
      const { promise, resolve, reject } = Promise.withResolvers();
      customerRechargeAuditAuditing(this.goodsAuditForm.id, this.Auditing).then(res => {
        this.GET_GoodsAuditList();
        resolve(true);
      }).catch(err => reject(err))
      return promise;
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    /** 获取充值列表 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      getCustomerRechargeAuditList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
  }
}
</script>
<style lang="scss" scoped>
.orderDetail {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>